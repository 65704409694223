<template>
  <v-card class="card-shadow border" color="white">
    <v-responsive class="mx-auto pa-8" max-width="650px">
      <v-card-title class="headline center">
        Create Staff
      </v-card-title>
      <v-form @submit.prevent="submitForm" v-model="valid">
        <v-text-field v-model="firstName" :rules="nameRules" label="First Name" required />
        <v-text-field v-model="lastName" :rules="nameRules" label="Last Name" required />
        <v-select v-model="roleValue" :items="roleItems" label="role" :rules="[v => !!v || 'role is required']" required />
        <v-text-field v-model="email" :rules="emailRules" label="Email" required />
        <v-text-field v-model="password" :rules="passwordRules" label="Password" type="password" required />
        <v-card-title v-if="successMessage" class="success-sec-btn mb-2">
          {{ successMessage }}
        </v-card-title>
        <v-card-title v-if="errorMessage" class="error-sec-btn mb-2">
          {{ errorMessage }}
        </v-card-title>
        <v-btn color="primary" type="submit" :disabled="(!valid) || (!roleValue.length)">
          Create
        </v-btn>
      </v-form>
    </v-responsive>
  </v-card>
</template>
<script>

export default {


  data: () => ({
    firstName: '',
    lastName: '',
    email: '',
    successMessage: '',
    errorMessage: '',
    password: '',
    valid: false,
    nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 20) || 'Name must be less than 20 characters'
    ],
    emailRules: [
      v => !!v || 'Email is required',
      v => /.+@.+\..+/.test(v) || 'Email must be valid'
    ],

    passwordRules: [
      v => !!v || 'Password is required',
      v => (v && v.length >= 6) || 'Password must be at least 6 characters'
    ],
    roleItems: ['foo', 'bar', 'fizz', 'buzz'],
    roleValue: [],
    roleData: []
  }),

  props: {
    currentServer: Object,
  },

  methods: {
    async submitForm() {
      try {
        // const baseurl = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_BASE_URL_LOCAL : this.currentServer.baseUrl
        const baseurl = process.env.VUE_APP_API_BASE_URL
        var myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('Authorization', `Bearer ${localStorage.getItem('token')}`);
        const role_id = this.roleData.find((item) => item?.name === this.roleValue)
        // const menu_ids = role_id?.menus.map((item) => item.id)
        const response = await fetch(`${baseurl}admin/staff_admins`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({
            'first_name': this.firstName,
            'last_name': this.lastName,
            'email': this.email,
            'password': this.password,
            'role_ids': role_id?.id,
            // 'menu_ids': menu_ids
          }),
        })
        const data = await response.json()
        this.data = JSON.stringify(data)
        if (response.status === 200) {
          this.successMessage = 'staff created successfully'
          setTimeout(() => {
            this.successMessage = '';
          }, 8000);
        } else {
          this.errorMessage = data.error;
          setTimeout(() => {
            this.errorMessage = '';
          }, 8000);
        }
      } catch (error) {
        this.errorMessage = 'Invalid username or password';
        setTimeout(() => {
          this.errorMessage = ''
        }, 8000);
      }
    },
    async fetchRoles() {
      try {
        // const baseurl = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_BASE_URL_LOCAL : this.currentServer.baseUrl;
        const baseurl = process.env.VUE_APP_API_BASE_URL
        var myHeaders = new Headers();
        myHeaders.append('Authorization', `Bearer ${localStorage.getItem('token')}`);
        const response = await fetch(`${baseurl}admin/roles`, {
          method: 'GET',
          'Accept': 'application/json',
          headers: myHeaders,
          'Content-Type': 'application/json',
          'redirect': 'follow',
        })
        if (!response.ok) {
          if (response.status == '401') {
            throw new Error('Unauthorized');
          } else {
            throw new Error('Network response was not ok');
          }
        }
        const data = await response.json();
        this.data = JSON.stringify(data);
        // Handle successful login here
        this.roleData = data
        const roleArray = data?.map((item) => item?.name)
        this.roleItems = roleArray
      } catch (error) {
        console.error('Error fetching data:', error);
        if (error.message.toUpperCase() === 'Unauthorized'.toUpperCase()) {
          this.$router.push('/admin-login');
        } else {
          alert('something went wrong')
        }
      }
    }
  },
  mounted() {
    this.fetchRoles();
  }
};
</script>
